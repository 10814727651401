import React from 'react'
import Layout from '../components/layout'
import { Jumbotron, Button } from 'reactstrap'
import { Link } from 'gatsby'

export default () => (
  <Layout>
    <Jumbotron className="text-center">
      <h2>Willkommen bei</h2>
      <h1 className="wlred-text">Waerder & Lorenz</h1>
      <h2 className="my-2">
        Steuerberater
        <span className="d-lg-none"> Partnerschafts-gesellschaft </span>
        <span className="d-none d-lg-inline"> Partnerschaftsgesellschaft </span>
        mbB
      </h2>
      <p className="lead text-muted">
        Die Sozietät wurde im April 2008 gegründet und ging aus den am Ort
        alteingesessenen Einzelkanzleien der beiden Partner hervor. Die Kanzlei
        befindet sich in einer historischen Villa im Ortskern des Ortsteils
        Hilgen von Burscheid. Angenehme Arbeitsatmosphäre und modernste Technik
        erlaubt es uns hier, das gesamte Spektrum der steuerlichen und
        betriebswirtschaftlichen Beratung für unsere Mandanten abzudecken.
      </p>

      <p>
        <Link to="/kontakt">
          <Button color="primary" className="my-2">
            Kontaktieren Sie uns
          </Button>
        </Link>
        <Button color="secondary" className="my-2 mx-2">
          <Link to="/ueberuns" className="text-white">
            Mehr über uns
          </Link>
        </Button>
      </p>
    </Jumbotron>
  </Layout>
)
